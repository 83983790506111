import React from "react";

/**
 * Function that will simply do a React.forwardRef, but it will keep component generic
 * Source:
 * https://www.totaltypescript.com/forwardref-with-generic-components
 * TODO: we might want to block usage of vanilla forwardRed using biome/eslint rule
 */
// biome-ignore lint/complexity/noBannedTypes: This is the intended usage
export function betterForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
): (props: P & React.RefAttributes<T>) => React.ReactElement | null {
  return React.forwardRef(render) as any as (
    props: P & React.RefAttributes<T>,
  ) => React.ReactElement | null;
}

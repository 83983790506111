import { KeyOfEnum } from "@kraaft/helper-types";

export const Color = {
  TRANSPARENT: "transparent",
  BLACK: "#000000",
  WHITE: "#FFFFFF",

  GREY_ANTIFLASH: "#F3F3F7",
  GREY_FRENCH: "#CBCBCD",
  GREY_TAUPE: "#84848B",
  GREY_JET: "#292929",

  BLUE_KRAAFT: "#2546CF",
  BLUE_SAVOY: "#435DCA",
  BLUE_VISTA: "#8294DC",
  BLUE_LAVENDER: "#DEE8F7",
  BLUE_COOL: "#EEF3FB",
  BLUE_AZURE: "#3A83F7",

  RED_TOMATO: "#EC5F4F",
  RED_SAVOY: "#EE6F61",
  RED_VISTA: "#F3998E",

  YELLOW_KRAAFT: "#F1CB46",
  YELLOW_EARTH: "#F4B863",

  GREEN_LAGOON: "#4FAA9F",
  GREEN_ACTIVE: "#27BD66",

  ORANGE_BASIC: "#E9824F",
} as const;

export type Color = KeyOfEnum<typeof Color>;

export const ColorStyle = {
  FONT_HIGH_EMPHASIS: Color.BLACK,
  FONT_LOW_EMPHASIS: Color.GREY_TAUPE,

  SEPARATOR: Color.GREY_FRENCH,

  BACKGROUND_LIGHT: Color.WHITE,
  BACKGROUND_STANDARD: Color.GREY_ANTIFLASH,

  ACTION_HOVERED: Color.GREY_ANTIFLASH,
  ACTION_SELECTED: Color.BLUE_COOL,

  ACTION_CTA: Color.BLUE_KRAAFT,
  ACTION_CTA_HOVERED: Color.BLUE_SAVOY,
  ACTION_CTA_DISABLED: Color.BLUE_VISTA,
  BACKGROUND_DISABLED: Color.GREY_ANTIFLASH,

  ACTION_DESCTRUCTIVE: Color.RED_TOMATO,
  ACTION_DESCTRUCTIVE_DISABLED: Color.RED_VISTA,
} as const;

export type ColorStyle = KeyOfEnum<typeof ColorStyle>;

export type ColorValue = ColorStyle | Color | (string & {}); // needed for autocompletion to work as string is a supertype of any string literal

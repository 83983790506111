import { Keyboard, Platform } from "react-native";
import { assert } from "ts-essentials";
import { v4 as uuidv4 } from "uuid";

import type { Falsy, Placement } from "@kraaft/helper-types";

/**
 * Detect if app is currently running in a mobile platform.
 */
export function isNative() {
  return Platform.select({
    ios: true,
    android: true,
    default: false,
  });
}

export function dismissNativeKeyboard() {
  if (isNative()) {
    Keyboard.dismiss();
  }
}

export function compact<T>(array: (T | Falsy)[]): T[] {
  return array.filter(Boolean) as T[];
}

export function pick<O extends {}, K extends keyof O>(object: O, keys: K[]) {
  return keys.reduce(
    (newObject, key) => {
      if (newObject && Object.prototype.hasOwnProperty.call(object, key)) {
        newObject[key] = object[key];
      }
      return newObject;
    },
    {} as Pick<O, K>,
  );
}

export function omitSingle<O extends {}, K extends keyof O>(
  object: O,
  key: K,
): Omit<O, K> {
  const { [key]: omitted, ...rest } = object;
  return rest;
}

export function omit<O extends {}, K extends keyof O>(
  object: O,
  keys: K[],
): Omit<O, (typeof keys)[number]> {
  return keys.reduce((newObject, key) => {
    return omitSingle(newObject, key) as any;
  }, object);
}

export function identity<T>(value: T): T {
  return value;
}

export function isKeyOf<R extends Record<string, unknown>>(
  record: R,
  key: string,
): key is keyof R & string {
  return Boolean(record[key]);
}

export function assertNever(value: never): never {
  assert(false);
}

export function isNotEmptyString(value: string | undefined): value is string {
  return Boolean(value && value.trim().length > 0);
}

export function isNotFalsy<T>(value: T | Falsy): value is T {
  return Boolean(value);
}

export function uuid() {
  return uuidv4();
}

export function isPlacementHorizontal(placement: Placement) {
  return placement.startsWith("left") || placement.startsWith("right");
}

export function isValueInRange(
  value: number,
  [leftBoundary, rightBoundary]: [number, number],
) {
  if (leftBoundary < rightBoundary) {
    return value >= leftBoundary && value <= rightBoundary;
  }
  return value >= rightBoundary && value <= leftBoundary;
}

export const arrayfy = <T>(value: T | T[]): T[] => {
  return Array.isArray(value) ? value : [value];
};

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

import { KeyOfEnum } from "@kraaft/helper-types";

export const PixelSize = {
  S4: 4,
  S8: 8,
  S16: 16,
  S24: 24,
  S32: 32,
  S36: 36,
  S40: 40,
  S48: 48,
  S56: 56,
} as const;

export type PixelSize = KeyOfEnum<typeof PixelSize>;

import moment from "moment";

import "intl-pluralrules";

import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import { getFallbackLanguages } from "@kraaft/shared/core/services/i18next/i18nextUtils";
import { LazyLoadingLocales } from "@kraaft/shared/core/services/i18next/lazyLoading";

import { languageDetector } from "./languageDetector/languageDetectorService";

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .use(LazyLoadingLocales)
  .init({
    debug: false,
    fallbackLng: getFallbackLanguages,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function (value, format) {
        if (value instanceof Date) {
          return moment(value).format(format);
        }
        return value;
      },
    },
  })
  .catch(console.error);

export { i18n };

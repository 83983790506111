import { DateTimePickerProps } from "./dateTimePicker.types";
import { DateTimePickerSheet } from "./dateTimePickerSheet";
import { DateTimePickerSheetContent } from "./dateTimePickerSheetContent";

export const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    onClose,
    visible,
    date,
    anchorRef,
    displayTime = false,
    onChange,
    forceValue = false,
    translate,
    locale,
  } = props;

  const handleChange = (dateChanged: Date) => {
    onChange(dateChanged);
    onClose();
  };

  const handleErase = () => {
    if (!props.forceValue) {
      props.onChange(undefined);
      onClose();
    }
  };

  return (
    <DateTimePickerSheet.Host
      open={visible}
      onClose={onClose}
      anchor={anchorRef}
      placement="bottom-end"
      noSwipe
    >
      <DateTimePickerSheetContent
        date={date}
        forceValue={forceValue}
        displayTime={displayTime}
        onChange={handleChange}
        onErase={handleErase}
        translate={translate}
        locale={locale}
      />
    </DateTimePickerSheet.Host>
  );
};

import { Identity } from "../../display/identity";
import { SheetDefinition } from "./sheet.types";

export const BlankSheetDefinition: SheetDefinition = {
  Host: Identity,
  Paper: Identity,
  Header: Identity,
  Content: Identity,
  GrowingContent: Identity,
  Footer: Identity,
};

import { makeStyles } from "@mui/styles";
import React, {
  type PropsWithChildren,
  useImperativeHandle,
  useMemo,
} from "react";
import { MarkRequired } from "ts-essentials";

import { assertNever } from "@kraaft/helper-functions";
import { useFileInput } from "@kraaft/helper-hooks";

import { DropIndicator } from "../droppable/dropIndicator";
import { useDroppable } from "../droppable/useDroppable";
import {
  FileDropperPickerHandle,
  FileDropperPickerProps,
  FileDropperPickerType,
} from "./fileDropperPicker.types";

function getAcceptedFileTypeFromPickerType(type: FileDropperPickerType) {
  switch (type) {
    case "camera":
    case "gallery": {
      return "image/*";
    }
    case "file": {
      return "*";
    }
    default:
      assertNever(type);
  }
}

export const FileDropperPicker = React.forwardRef<
  FileDropperPickerHandle,
  PropsWithChildren<
    MarkRequired<
      FileDropperPickerProps,
      "webDropAcceptType" | "webOnDrop" | "webOnDropFiles"
    >
  >
>(
  (
    {
      children,
      pickers,
      translate,
      webDropAcceptType: acceptType,
      webOnDrop: onDrop,
      webOnDropFiles: onDropFiles,
      webDropAcceptSystemFile,
    },
    externalRef,
  ) => {
    const classes = useStyles();

    const [droppableContainerRef, { canDrop, isOver }] = useDroppable({
      acceptType,
      acceptSystemFile: webDropAcceptSystemFile ?? true,
      onDrop,
      onDropFiles,
    });

    const acceptedFileTypesForPickers = useMemo(() => {
      const acceptedFiles: string[] = [];

      if (Array.isArray(pickers)) {
        for (const picker of pickers) {
          acceptedFiles.push(getAcceptedFileTypeFromPickerType(picker));
        }
      } else if (pickers !== undefined) {
        acceptedFiles.push(getAcceptedFileTypeFromPickerType(pickers));
      }
      return [...new Set(acceptedFiles)];
    }, [pickers]);

    const { getInputProps, open } = useFileInput({
      accept: acceptedFileTypesForPickers,
      onAccepted: onDropFiles,
    });

    const handle = useMemo<FileDropperPickerHandle>(
      () => ({ openFilePicker: open }),
      [open],
    );

    useImperativeHandle(externalRef, () => handle);

    return (
      <div ref={droppableContainerRef} className={classes.container}>
        <input {...getInputProps()} />
        {children}
        {canDrop && (
          <DropIndicator isActive={isOver} text={translate("depositHere")} />
        )}
      </div>
    );
  },
);

const useStyles = makeStyles({
  container: {
    width: "100%",
    position: "relative",
  },
});

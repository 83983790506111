import { useMemo } from "react";

import { StyledNestedTextMatcher } from "../../../display/styledNestedText/styledNestedText.types";
import { useExplodedTextFromMatchers } from "../../../display/styledNestedText/useExplodedTextFromMatchers";

export function useFirstTextMatch(
  text: string,
  matchers: StyledNestedTextMatcher[] | undefined,
) {
  const [tokenizedText, tokenRegExp, matches] = useExplodedTextFromMatchers(
    text,
    matchers,
  );

  return useMemo(() => {
    const firstMatchedPart = tokenizedText
      .split(tokenRegExp)
      .filter(Boolean)
      .find((part) => Boolean(matches[part]));

    if (firstMatchedPart) {
      return matches[firstMatchedPart];
    }
    return undefined;
  }, [matches, tokenRegExp, tokenizedText]);
}

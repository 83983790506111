import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { PropsWithChildren, useMemo } from "react";
import withScrolling, { createVerticalStrength } from "react-dnd-scrolling";

const defaultLinearVerticalStrength = createVerticalStrength(200);
const ScrollingDiv = withScrolling("div");

interface ScrollingContainerProps {
  id?: string;
  customScrollThreshold?: number;
  className?: string;
}

export const ScrollingContainer = ({
  children,
  id,
  customScrollThreshold,
  className,
}: PropsWithChildren<ScrollingContainerProps>) => {
  const classes = useStyles();

  const verticalStrength = useMemo(
    () =>
      customScrollThreshold
        ? createVerticalStrength(customScrollThreshold)
        : defaultLinearVerticalStrength,
    [customScrollThreshold],
  );

  return (
    <ScrollingDiv
      id={id}
      className={clsx(classes.container, className)}
      verticalStrength={verticalStrength}
    >
      {children}
    </ScrollingDiv>
  );
};

const useStyles = makeStyles({
  container: {
    overflow: "auto",
  },
});

import {
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  type KeyboardAvoidingViewProps,
  Platform,
} from "react-native";

/**
 * 🚨 Ensure that the KeyboardAvoidingView has a fixed height to avoid onLayout cycles
 */
export const KeyboardAvoidingView = (
  keyboardAvoidingViewProps: KeyboardAvoidingViewProps,
) => {
  return (
    <RNKeyboardAvoidingView
      behavior={Platform.select({ ios: "padding" })}
      {...keyboardAvoidingViewProps}
    />
  );
};

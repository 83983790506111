import { ReactNode, useMemo, useState } from "react";

import { omitSingle } from "@kraaft/helper-functions";

import { PortalDataContext, PortalManagementContext } from "./portalContext";

export type PortalWrapperProps = {
  children: ReactNode;
};

const addHostnameElement = ({
  elements,
  element,
  keyToAdd,
}: {
  elements: [string, ReactNode][];
  element: ReactNode;
  keyToAdd: string;
}): [string, ReactNode][] => {
  const index = elements.findIndex(([key, _]) => key === keyToAdd);

  if (index < 0) {
    return [...elements, [keyToAdd, element]];
  }

  const newElements = elements.slice(0);
  newElements[index] = [keyToAdd, element];
  return newElements;
};

const removeHostnameElement = ({
  elements,
  keyToRemove,
}: {
  elements: [string, ReactNode][];
  keyToRemove: string;
}): [string, ReactNode][] => elements.filter(([key, _]) => key !== keyToRemove);

export const PortalProvider: React.FunctionComponent<PortalWrapperProps> = ({
  children,
}) => {
  const [elementMap, setElementMap] = useState<
    Record<string, [string, ReactNode][]>
  >({});

  const value = useMemo(
    () => ({
      addElement: (element: ReactNode, keyToAdd: string, hostname: string) => {
        setElementMap((eltMap) => ({
          ...eltMap,
          [hostname]: addHostnameElement({
            element,
            keyToAdd,
            elements: eltMap?.[hostname] ?? [],
          }),
        }));
      },
      removeElement: (keyToRemove: string, hostname: string) =>
        setElementMap((eltMap) => {
          const hostElements = removeHostnameElement({
            keyToRemove,
            elements: eltMap?.[hostname] ?? [],
          });
          return hostElements.length > 0
            ? {
                ...eltMap,
                [hostname]: hostElements,
              }
            : omitSingle(eltMap, hostname);
        }),
    }),
    [],
  );

  return (
    <PortalManagementContext.Provider value={value}>
      <PortalDataContext.Provider value={elementMap}>
        {children}
      </PortalDataContext.Provider>
    </PortalManagementContext.Provider>
  );
};

import { AnchoredSheetContent } from "../anchoredSheet.content";
import { AnchoredSheetFooter } from "../anchoredSheet.footer";
import { AnchoredSheetGrowingContent } from "../anchoredSheet.growingContent";
import { AnchoredSheetHeader } from "../anchoredSheet.header";
import { AnchoredSheetHost } from "../anchoredSheet.host";
import { AnchoredSheetPaper } from "../anchoredSheet.paper";
import { AnchoredSheetDefinition } from "./anchoredSheet.definition.types";

export const anchoredSheetDefinition: AnchoredSheetDefinition = {
  Host: AnchoredSheetHost,
  Paper: AnchoredSheetPaper,
  Header: AnchoredSheetHeader,
  Content: AnchoredSheetContent,
  GrowingContent: AnchoredSheetGrowingContent,
  Footer: AnchoredSheetFooter,
};

import { makeStyles } from "@mui/styles";
import { clsx } from "clsx";
import React from "react";

import { Color, ColorStyle, IconName, Preloader, Spacing } from "@kraaft/ui";

import { Icon, Text } from "../../display";

export interface ActionSheetItemProps {
  onClick: () => void;
  icon?: IconName;
  label: string;
  destructive?: boolean;
  loading?: boolean;
}

export const ActionSheetItem = React.forwardRef<
  HTMLButtonElement,
  ActionSheetItemProps
>(({ onClick, icon, label, destructive, loading }, forwardedRef) => {
  const classes = useStyles();

  const color = destructive
    ? ColorStyle.ACTION_DESCTRUCTIVE
    : ColorStyle.FONT_HIGH_EMPHASIS;

  return (
    <button
      aria-label={label}
      onClick={onClick}
      className={clsx(classes.container)}
      disabled={loading}
      type={"button"}
      ref={forwardedRef}
    >
      {loading ? (
        <Preloader
          backgroundColor={Color.TRANSPARENT}
          absoluteFill
          color={color}
          size="small"
        />
      ) : (
        <>
          {icon && <Icon name={icon} size="MEDIUM" color={color} />}
          <Text size="MEDIUM" color={color} weight={"regular"}>
            {label}
          </Text>
        </>
      )}
    </button>
  );
});

const useStyles = makeStyles({
  container: {
    display: "inline-flex" as const,
    border: 0,
    outline: 0,
    cursor: "pointer" as const,
    position: "relative" as const,
    userSelect: "none" as const,
    textAlignVertical: "center" as const,
    textDecoration: "none" as const,

    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
    paddingVertical: Spacing.S8,
    paddingLeft: Spacing.S16,
    // Asked by valentin because of the little offset caused by the icon (the icon is not in the center of its box)
    paddingRight: Spacing.S16 + 4,
    minHeight: Spacing.S40,
    backgroundColor: ColorStyle.BACKGROUND_LIGHT,
    "&:hover": {
      backgroundColor: ColorStyle.BACKGROUND_STANDARD,
    },
    "&:active": {
      opacity: 0.5,
    },
  },
});

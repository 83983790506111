import React, { ReactNode, RefObject } from "react";
import { StyleSheet, View } from "react-native";

import { Placement } from "@kraaft/helper-types";
import { PlatformSelect } from "@kraaft/shared/core/utils/platformSelect/platformSelect";

import { Spacing } from "../../constants";
import { Sheet } from "../../layout";
import { isNative } from "@kraaft/helper-functions";

export const ActionSheetDefinition = Sheet({
  web: "anchored",
  native: "bottom",
});

type ActionSheetHostProps = {
  open: boolean;
  onClose: () => void;
  placement?: Placement;
  children: ReactNode;
} & PlatformSelect<{
  web: { anchor: RefObject<Element> | null };
  native: { anchor?: RefObject<unknown> | null };
}>;

export const ActionSheetHost = ({
  open,
  onClose,
  anchor,
  placement,
  children,
}: ActionSheetHostProps) => {
  return (
    <ActionSheetDefinition.Host
      open={open}
      onClose={onClose}
      anchor={anchor}
      placement={placement}
    >
      {children}
    </ActionSheetDefinition.Host>
  );
};

export const ActionSheetContent = ({ children }: React.PropsWithChildren) => {
  return (
    <ActionSheetDefinition.Paper
      noPadding={!isNative()}
      withHandle={isNative()}
    >
      <View style={styles.extraPaddingView}>
        <ActionSheetDefinition.Content noPadding>
          {children}
        </ActionSheetDefinition.Content>
      </View>
    </ActionSheetDefinition.Paper>
  );
};

const styles = StyleSheet.create({
  extraPaddingView: {
    paddingTop: isNative() ? Spacing.S16 : Spacing.S8,
    paddingBottom: isNative() ? 0 : Spacing.S8,
  },
});

import { CSSProperties } from "react";
import { ImageStyle, Platform, TextStyle, ViewStyle } from "react-native";

import { omit, pick } from "@kraaft/helper-functions";
import { DistributedKeyOf } from "@kraaft/helper-types";

export function pickFromStyle<S extends ViewStyle | TextStyle | ImageStyle>(
  style: S,
  keys: DistributedKeyOf<S>[],
) {
  return pick(style, keys) as S;
}

export function omitFromStyle<S extends ViewStyle | TextStyle | ImageStyle>(
  style: S,
  keys: DistributedKeyOf<S>[],
) {
  return omit(style, keys);
}

export function webOnlyStyle(style: CSSProperties) {
  return Platform.select({
    web: style,
  }) as Record<string, unknown> | undefined;
}

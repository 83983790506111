import { PropsWithChildren, useMemo, useState } from "react";

import { createMeshContext, useMeshContextSetup } from "@kraaft/helper-hooks";

import { AnimationState as BaseAnimationState } from "./animation.types";

type AnimationState = "undetermined" | BaseAnimationState;

// Context
type AnimationContextValue = {
  animationState: AnimationState;
};

export const AnimationContext = createMeshContext<AnimationContextValue>();

interface AnimationContextProviderProps {
  animationState?: AnimationState;
}
export const AnimationContextProvider = ({
  children,
  animationState,
}: PropsWithChildren<AnimationContextProviderProps>) => {
  const contextValue = useMeshContextSetup<AnimationContextValue>({
    animationState: animationState ?? "undetermined",
  });

  return (
    <AnimationContext.Provider value={contextValue}>
      {children}
    </AnimationContext.Provider>
  );
};

// Hook
type AnimationCallbackProps = {
  onEntering: () => void;
  onEntered: () => void;
  onExiting: () => void;
  onExited: () => void;
};

interface UseAnimationContextValueProps {
  defaultAnimationState: BaseAnimationState;
}

export const useAnimationContext = ({
  defaultAnimationState,
}: UseAnimationContextValueProps): [AnimationState, AnimationCallbackProps] => {
  const [animationState, setAnimationState] = useState<AnimationState>(
    defaultAnimationState,
  );

  const animationCallbackProps = useMemo(
    () => ({
      onEntering: () => {
        setAnimationState("entering");
      },

      onEntered: () => {
        setAnimationState("entered");
      },

      onExiting: () => {
        setAnimationState("exiting");
      },

      onExited: () => {
        setAnimationState("exited");
      },
    }),
    [],
  );

  return [animationState, animationCallbackProps];
};

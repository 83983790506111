import {
  DependencyList,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FlatList } from "react-native";

import { AnyUnexplained } from "@kraaft/shared/core/types";

export function useToggle(
  initialState: boolean,
): [boolean, (state?: boolean) => void] {
  const [isToggled, setToggled] = useState(initialState);

  const toggle = useCallback((newState?: boolean) => {
    if (newState === true || newState === false) {
      setToggled(newState);
    } else {
      setToggled((currentState) => !currentState);
    }
  }, []);

  return [isToggled, toggle];
}

// Fix scroll direction (https://github.com/necolas/react-native-web/issues/995#issuecomment-630012292)
export function useInvertedWheel(): React.RefObject<FlatList> {
  const ref = useRef<FlatList>(null);

  const invertWheel = useCallback((event: React.WheelEvent) => {
    event.preventDefault();
    if (ref.current !== null) {
      ref.current.getScrollableNode().scrollTop -= event.deltaY;
    }
  }, []);

  useEffect(() => {
    const currentRef = ref.current;
    const scrollableNode = currentRef?.getScrollableNode();

    if (currentRef !== null) {
      scrollableNode.addEventListener("wheel", invertWheel);

      // Makes scrolling fast in Safari and Firefox (https://stackoverflow.com/a/24157294)
      currentRef.setNativeProps({
        style: {
          transform: "translate3d(0,0,0) scaleY(-1)",
        },
      });
    }

    return () => {
      scrollableNode?.removeEventListener("wheel", invertWheel);
    };
  }, [ref, invertWheel]);

  return ref;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

type Tuple<T extends AnyUnexplained[]> = [...T];

export function useCallbackRealtime<
  const T extends AnyUnexplained[],
  const R extends AnyUnexplained[],
  const U extends Tuple<R>,
  RET,
>(
  fn: (refs: U, ...args: T) => RET,
  fnDeps: DependencyList,
  values: U,
): (...args: T) => RET {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refs = useRef<U>(values);
  values.forEach((v, index) => (refs.current[index] = v));

  return useCallback(
    (...args: T[]) => {
      return fnRef.current(refs.current, ...(args as T));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...fnDeps],
  );
}

export function useEffectRealtime<
  const T extends AnyUnexplained[],
  const R extends AnyUnexplained[],
  const U extends Tuple<R>,
>(
  fn: ((refs: U, ...args: T) => void) | ((refs: U, ...args: T) => () => void),
  fnDeps: DependencyList,
  values: U,
) {
  const fnRef = useRef(fn);
  fnRef.current = fn;
  const refs = useRef<U>(values);
  values.forEach((v, index) => (refs.current[index] = v));

  useEffect(
    (...args: T[]) => {
      return fnRef.current(refs.current, ...(args as T));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...fnDeps],
  );
}

export function useEffectDifference<
  R extends AnyUnexplained[],
  D extends Tuple<R>,
>(fn: (oldDeps: D) => void, deps: D) {
  const currentFn = useRef(fn);
  currentFn.current = fn;

  const old = useRef(deps);

  useEffect(() => {
    currentFn.current(old.current);
    old.current = deps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

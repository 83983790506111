import uniq from "lodash/uniq";
import React, { useImperativeHandle, useMemo } from "react";

import { assertNever } from "@kraaft/helper-functions";
import { useFileInput } from "@kraaft/helper-hooks";

import {
  FilePickerHandle,
  FilePickerProps,
  FilePickerType,
} from "./filePicker.types";

function getAcceptedFileTypeFromPickerType(type: FilePickerType) {
  switch (type) {
    case "gallery": {
      return "image/*";
    }
    case "file": {
      return "*";
    }
    default:
      assertNever(type);
  }
}

// Ideally, this should be just a hook
export const FilePicker = React.forwardRef<FilePickerHandle, FilePickerProps>(
  ({ pickers, onWebFiles }, forwardedRef) => {
    useImperativeHandle(forwardedRef, () => handle);

    const acceptedFileTypesForPickers = useMemo(() => {
      const acceptedFiles = (Array.isArray(pickers) ? pickers : [pickers]).map(
        (it) => getAcceptedFileTypeFromPickerType(it),
      );

      return uniq(acceptedFiles);
    }, [pickers]);

    const { getInputProps, open } = useFileInput({
      accept: acceptedFileTypesForPickers,
      onAccepted: onWebFiles,
    });

    const handle = useMemo<FilePickerHandle>(
      () => ({
        openFilePicker: open,
      }),
      [open],
    );

    return <input {...getInputProps()} />;
  },
);

import { assertNever, isKeyOf } from "@kraaft/helper-functions";

import { Color, ColorStyle, ColorValue } from "../constants/color";
import { FontSize } from "../constants/fontSize";
import { IconSize } from "../constants/iconSize";
import { Spacing } from "../constants/spacing";

export function getColorFromColorValue(color: ColorValue) {
  if (isKeyOf(ColorStyle, color)) {
    return ColorStyle[color];
  }
  if (isKeyOf(Color, color)) {
    return Color[color];
  }
  return color;
}

export function getFontSizeFromSizeValue(size: FontSize | number) {
  if (typeof size === "number") {
    return size;
  }
  if (isKeyOf(FontSize, size)) {
    return FontSize[size];
  }
  assertNever(size);
}

export function getIconSizeFromSizeValue(size: IconSize | number) {
  if (typeof size === "number") {
    return size;
  }
  if (isKeyOf(IconSize, size)) {
    return IconSize[size];
  }
  assertNever(size);
}

export function getSpacingFromSizeValue(size: Spacing | number) {
  if (typeof size === "number") {
    return size;
  }
  if (isKeyOf(Spacing, size)) {
    return Spacing[size];
  }
  assertNever(size);
}

import { makeStyles } from "@mui/styles";

import {
  baseStyle,
  generateButtonStyle,
} from "@kraaft/shared/components/kButton/kButton.styles";
import { KButtonType } from "@kraaft/shared/components/kButton/kButtonProps";
import { FontSize, Spacing } from "@kraaft/ui";

export const useStyles = makeStyles({
  tooltip: {
    padding: Spacing.S8,
    fontSize: FontSize.SMALL,
  },
  root: {
    display: "inline-flex" as const,
    border: 0,
    outline: 0,
    cursor: "pointer" as const,
    position: "relative" as const,
    userSelect: "none" as const,
    textAlignVertical: "center" as const,
    textDecoration: "none" as const,
    ...baseStyle.button,
    ...baseStyle.textNormal,
  },
  sizeNormal: baseStyle.sizeNormal,
  sizeSmall: baseStyle.sizeSmall,
  condensedNormal: baseStyle.condensedNormal,
  condensedSmall: baseStyle.condensedSmall,
  Primary: {
    ...generateButtonStyle({ variant: KButtonType.PRIMARY }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      disabled: true,
    }),
  },
  destructivePrimary: {
    ...generateButtonStyle({ variant: KButtonType.PRIMARY, destructive: true }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      destructive: true,
      disabled: true,
    }),
  },
  Secondary: {
    ...generateButtonStyle({ variant: KButtonType.SECONDARY }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.SECONDARY,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.SECONDARY,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.SECONDARY,
      disabled: true,
    }),
  },
  destructiveSecondary: {
    ...generateButtonStyle({
      variant: KButtonType.SECONDARY,
      destructive: true,
    }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.SECONDARY,
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.SECONDARY,
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.SECONDARY,
      destructive: true,
      disabled: true,
    }),
  },
  Tertiary: {
    ...generateButtonStyle({ variant: KButtonType.TERTIARY }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.TERTIARY,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.TERTIARY,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.TERTIARY,
      disabled: true,
    }),
  },
  destructiveTertiary: {
    ...generateButtonStyle({
      variant: KButtonType.TERTIARY,
      destructive: true,
    }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.TERTIARY,
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.TERTIARY,
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.TERTIARY,
      destructive: true,
      disabled: true,
    }),
  },
  Quaternary: {
    ...baseStyle.quarternaryPaddings,
    ...generateButtonStyle({ variant: KButtonType.QUATERNARY }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      disabled: true,
    }),
  },
  destructiveQuaternary: {
    ...baseStyle.quarternaryPaddings,
    ...generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      destructive: true,
    }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.QUATERNARY,
      destructive: true,
      disabled: true,
    }),
  },
  Map: {
    ...generateButtonStyle({ variant: KButtonType.MAP }),
    "&:hover": generateButtonStyle({ variant: KButtonType.MAP, hovered: true }),
    "&:active": generateButtonStyle({
      variant: KButtonType.MAP,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.MAP,
      disabled: true,
    }),
  },
  destructiveMap: {
    ...generateButtonStyle({ variant: KButtonType.MAP, destructive: true }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.MAP,
      destructive: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.MAP,
      destructive: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.MAP,
      destructive: true,
      disabled: true,
    }),
  },

  // selected & alignLeft must be defined after other styles to take CSS precedence
  selected: {
    ...generateButtonStyle({ variant: KButtonType.PRIMARY, selected: true }),
    "&:hover": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      selected: true,
      hovered: true,
    }),
    "&:active": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      selected: true,
      hovered: true,
    }),
    "&:disabled": generateButtonStyle({
      variant: KButtonType.PRIMARY,
      selected: true,
      disabled: true,
    }),
  },
  alignLeft: baseStyle.buttonAlignLeft,
});

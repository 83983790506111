import { PropsWithChildren, useContext, useEffect, useRef } from "react";

import { DEFAULT_PORTAL_HOSTNAME } from "../portalConstants";
import { PortalManagementContext } from "../portalContext";

export interface GenericPortalProps {
  hostname?: string;
}

export const GenericPortal = ({
  children,
  hostname = DEFAULT_PORTAL_HOSTNAME,
}: PropsWithChildren<GenericPortalProps>) => {
  const { addElement, removeElement } = useContext(PortalManagementContext);
  const key = useRef(Date.now() + Math.random().toString(36));

  useEffect(() => {
    addElement(children, key.current, hostname);
  }, [children, addElement, hostname]);

  useEffect(
    () => () => removeElement(key.current, hostname),
    [removeElement, hostname],
  );

  return null;
};

import { makeStyles } from "@mui/styles";
import React, { useMemo } from "react";
import IcoMoon from "react-icomoon";
import { StyleProp, TextStyle, View } from "react-native";

import iconSet from "../../assets/icons/selection.json";
import { ColorValue } from "../../constants/color";
import { IconSize } from "../../constants/iconSize";
import {
  getColorFromColorValue,
  getIconSizeFromSizeValue,
} from "../../utils/constants.utils";
import { IconName } from "./icon.types";

interface IconProps {
  name: IconName;
  color?: ColorValue;
  size?: IconSize | number;
  className?: string;
  style?: StyleProp<TextStyle>;
  testID?: string;
  id?: string;
  fixChromePaste?: boolean;
}

export const Icon = React.forwardRef<View, IconProps>(
  (
    {
      name,
      color,
      size = "MEDIUM",
      className,
      testID,
      id,
      fixChromePaste,
      style,
    },
    ref,
  ) => {
    const classes = useStyles();

    const iconColor = useMemo(
      () => (color ? getColorFromColorValue(color) : undefined),
      [color],
    );
    const iconSize = useMemo(() => getIconSizeFromSizeValue(size), [size]);

    return (
      <View ref={ref} nativeID={id} style={style}>
        {/* https://github.com/facebook/react/issues/8461 */}
        {fixChromePaste && <span className={classes.spanBuffer}>&nbsp;</span>}
        <IcoMoon
          data-testid={testID}
          data-icon-name={name}
          iconSet={iconSet}
          icon={name}
          color={iconColor}
          size={iconSize}
          className={className}
        />
      </View>
    );
  },
);

const useStyles = makeStyles({
  spanBuffer: {
    position: "absolute",
  },
});

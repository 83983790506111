/* eslint-disable no-bitwise */
import { Color } from "@kraaft/ui";

export function shadeColor(c: string, percent: number) {
  let R = parseInt(c.substring(1, 3), 16);
  let G = parseInt(c.substring(3, 5), 16);
  let B = parseInt(c.substring(5, 7), 16);

  R = Math.floor(R * (1 + percent));
  G = Math.floor(G * (1 + percent));
  B = Math.floor(B * (1 + percent));

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  let RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export function opacify(hexColor: string, opacity: number) {
  "worklet";
  if (!hexColor.startsWith("#")) {
    return hexColor;
  }
  const newOpacity = opacity < 0 ? 0 : opacity > 1 ? 1 : opacity;
  const f = parseInt(hexColor.slice(1), 16);
  const redValue = f >> 16;
  const greenValue = (f >> 8) & 0x00ff;
  const blueValue = f & 0x0000ff;

  function processValue(value: number) {
    return Math.floor(255 - newOpacity * (255 - value));
  }
  return (
    "#" +
    processValue(redValue).toString(16) +
    processValue(greenValue).toString(16) +
    processValue(blueValue).toString(16)
  );
}

export function alpha(hexColor: string, opacity: number) {
  if (hexColor.length > 7) {
    console.warn("You are trying to alpha a color already containing an alpha");
    hexColor = hexColor.slice(0, -2);
  }

  if (hexColor.length < 6) {
    console.warn(
      "You passed a 3 characters length color, this is not supported",
    );
  }

  const hexAlpha = Math.floor(opacity * 255)
    .toString(16)
    .padStart(2, "0");

  return `${hexColor}${hexAlpha}`;
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }
  const [_, r, g, b] = result;
  if (r && g && b) {
    return {
      r: parseInt(r, 16),
      g: parseInt(g, 16),
      b: parseInt(b, 16),
    };
  }
  return null;
}

type Contrast = "light" | "dark";

// Color should be hex string
export function getContrastVariantFromColor(color: string): Contrast {
  const rgb = hexToRgb(color);

  if (!rgb) {
    return "dark";
  }

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000,
  );
  return brightness > 200 ? "dark" : "light";
}

export const getColorFromContrast = (contrast: Contrast) =>
  contrast === "dark" ? Color.GREY_JET : Color.WHITE;

export const getContrastColorFromColor = (color: string) => {
  return getColorFromContrast(getContrastVariantFromColor(color));
};

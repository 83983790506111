import { StyleSheet } from "react-native";

import { KButtonType } from "@kraaft/shared/components/kButton/kButtonProps";
import { ensureValidStyleSheet } from "@kraaft/shared/core/utils";
import { Color, ColorStyle, PixelSize, Radius, Spacing } from "@kraaft/ui";

import { getDisabledColor, getHoveredColor } from "./button.colors";

interface MakeColorProps {
  variant: KButtonType;
  destructive?: boolean;
  disabled?: boolean;
  selected?: boolean;
  hovered?: boolean;
  contentColor?: string;
}

interface ButtonStyle {
  color: string;
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
  borderStyle?: "solid";
}

export const generateButtonStyle = ({
  variant,
  destructive = false,
  selected = false,
  disabled = false,
  hovered = false,
  contentColor,
}: MakeColorProps) => {
  const baseColors = generateBaseColors(variant, destructive, hovered);

  if (selected) {
    baseColors.color = getHoveredColor(ColorStyle.ACTION_CTA, hovered);
    baseColors.backgroundColor = Color.BLUE_LAVENDER;
  }

  if (contentColor) {
    baseColors.color = contentColor;
  }

  if (disabled) {
    for (const [key, value] of Object.entries(baseColors)) {
      if (value) {
        baseColors[key as keyof typeof baseColors] = getDisabledColor(value);
      }
    }
  }

  const style: ButtonStyle = baseColors;
  if (baseColors.borderColor) {
    style.borderWidth = 1;
    style.borderStyle = "solid";
  }

  return style;
};

// eslint-disable-next-line complexity
function generateBaseColors(
  variant: KButtonType,
  destructive: boolean,
  hovered: boolean,
): { color: string; backgroundColor: string; borderColor?: string } {
  switch (variant) {
    case KButtonType.PRIMARY:
      return {
        color: Color.WHITE,
        backgroundColor: getHoveredColor(
          destructive ? ColorStyle.ACTION_DESCTRUCTIVE : ColorStyle.ACTION_CTA,
          hovered,
        ),
      };
    case KButtonType.SECONDARY:
      return {
        color: getHoveredColor(
          destructive ? ColorStyle.ACTION_DESCTRUCTIVE : ColorStyle.ACTION_CTA,
          hovered,
        ),
        backgroundColor: hovered ? ColorStyle.BACKGROUND_STANDARD : Color.WHITE,
        borderColor: getHoveredColor(
          destructive ? ColorStyle.ACTION_DESCTRUCTIVE : ColorStyle.ACTION_CTA,
          hovered,
        ),
      };
    case KButtonType.TERTIARY:
      return {
        color: getHoveredColor(
          destructive
            ? ColorStyle.ACTION_DESCTRUCTIVE
            : ColorStyle.FONT_HIGH_EMPHASIS,
          hovered,
        ),
        backgroundColor: hovered ? Color.BLUE_LAVENDER : Color.TRANSPARENT,
      };
    case KButtonType.QUATERNARY:
      return {
        color: getHoveredColor(
          destructive ? ColorStyle.ACTION_DESCTRUCTIVE : ColorStyle.ACTION_CTA,
          hovered,
        ),
        backgroundColor: Color.TRANSPARENT,
      };
    case KButtonType.MAP:
      return {
        color: getHoveredColor(
          destructive
            ? ColorStyle.ACTION_DESCTRUCTIVE
            : ColorStyle.FONT_HIGH_EMPHASIS,
          hovered,
        ),
        backgroundColor: Color.WHITE,
        borderColor: getHoveredColor(
          destructive ? ColorStyle.ACTION_DESCTRUCTIVE : ColorStyle.ACTION_CTA,
          hovered,
        ),
      };
  }
}

export const baseStyle = {
  button: {
    flexDirection: "row",
    borderRadius: Radius.SMALL,
    width: "auto",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 0,
    gap: Spacing.S8,
  },
  buttonAlignLeft: {
    justifyContent: "flex-start",
    paddingLeft: 0,
  },
  quarternaryPaddings: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  sizeNormal: {
    paddingTop: Spacing.S8,
    paddingBottom: Spacing.S8,
    paddingLeft: Spacing.S16,
    paddingRight: Spacing.S16,
    height: PixelSize.S40,
  },
  sizeSmall: {
    paddingTop: Spacing.S4,
    paddingBottom: Spacing.S4,
    paddingLeft: Spacing.S8,
    paddingRight: Spacing.S8,
    height: PixelSize.S32,
  },
  condensedNormal: {
    paddingLeft: Spacing.S8,
    paddingRight: Spacing.S8,
    gap: 0,
  },
  condensedSmall: {
    paddingLeft: Spacing.S4,
    paddingRight: Spacing.S4,
    gap: 0,
  },
  textNormal: {
    fontSize: 16,
    fontFamily: "Apercu-Medium",
    margin: 0,
  },
} as const;

export const styles = StyleSheet.create(ensureValidStyleSheet(baseStyle));

import { KeyOfEnum } from "@kraaft/helper-types";

export const Radius = {
  NONE: 0,
  MINI: 4,
  SMALL: 6,
  MEDIUM: 12,
  LARGE: 16,
  MAXIMUM: 10000,
} as const;

export type Radius = KeyOfEnum<typeof Radius>;

import {
  Fragment,
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useRef,
} from "react";

import { DEFAULT_PORTAL_HOSTNAME } from "./portalConstants";
import { PortalDataContext } from "./portalContext";

const defaultRenderElements = (
  elements: [string, ReactNode][],
): ReactElement => (
  <>
    {elements.map(([key, node]) => (
      <Fragment key={key}>{node}</Fragment>
    ))}
  </>
);

interface PortalHostProps {
  hostname?: string;
  renderElements?: (elements: [string, ReactNode][]) => ReactElement;
}

export const PortalHost = ({
  hostname = DEFAULT_PORTAL_HOSTNAME,
  renderElements = defaultRenderElements,
}: PortalHostProps) => {
  const defaultElementsRef = useRef<[string, ReactNode][]>([]);
  const elementMap = useContext(PortalDataContext);
  const elements = elementMap?.[hostname] ?? defaultElementsRef.current;

  return useMemo(() => renderElements(elements), [renderElements, elements]);
};

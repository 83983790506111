import { KeyOfEnum } from "@kraaft/helper-types";

export const Spacing = {
  NONE: 0,
  S4: 4,
  S8: 8,
  S12: 12,
  S16: 16,
  S24: 24,
  S32: 32,
  S40: 40,
  S72: 72,
} as const;

export type Spacing = KeyOfEnum<typeof Spacing>;

import { forwardRef, MouseEventHandler } from "react";
import { Tooltip } from "@mui/material";
import clsx from "clsx";

import { generateButtonStyle } from "@kraaft/shared/components/kButton/kButton.styles";
import {
  KButtonProps,
  KButtonSize,
  KButtonType,
} from "@kraaft/shared/components/kButton/kButtonProps";
import { webHighlightOnFocus } from "@kraaft/shared/core/utils/highlightOnFocus";
import { Color, Icon, IconSize, Preloader } from "@kraaft/ui";

import { useStyles } from "./kButton.styles";

export type KButtonHandle = HTMLButtonElement;
export type WebKButtonProps = Omit<KButtonProps, "onPress"> & {
  onPress?: MouseEventHandler<HTMLButtonElement>;
  checked?: boolean;
};

const KButton = forwardRef<KButtonHandle, WebKButtonProps>(
  // eslint-disable-next-line complexity
  (
    {
      variant = KButtonType.PRIMARY,
      destructive = false,
      size = KButtonSize.NORMAL,
      iconSize = "MEDIUM",
      alignContent = "center",
      type,
      style, // Could we get rid of that?
      disabled,
      testID,
      selected,
      condensed,
      tooltip = "",
      tooltipPlacement,
      contentColor, // Could we get rid of that?
      text,
      loading,
      icon,
      width,
      id,
      onPress,
      onPressIn,
      accessibilityLabel,
      autoFocus,
      checked,
    },
    ref,
  ): JSX.Element => {
    const classes = useStyles();

    const hasText = (text?.length ?? 0) > 0;

    const variantColor = generateButtonStyle({
      variant,
      destructive,
      selected,
      disabled,
      contentColor,
    }).color;

    const iconColor = loading ? Color.TRANSPARENT : variantColor;
    const textColor = loading ? Color.TRANSPARENT : contentColor; // keep undefined as much as possible not to override pseudo-selectors

    const destructivePrefix = destructive ? "destructive" : "";
    const buttonClassName = clsx(
      classes.root,
      classes[`size${size}`],
      classes[`${destructivePrefix}${variant}`],
      (condensed || !hasText) && classes[`condensed${size}`],
      selected && classes.selected,
      alignContent === "left" && classes.alignLeft,
    );

    return (
      <Tooltip
        title={tooltip}
        key={text}
        classes={{
          tooltip: classes.tooltip,
        }}
        placement={tooltipPlacement}
      >
        <button
          className={buttonClassName}
          role={checked !== undefined ? "checkbox" : undefined}
          aria-checked={checked}
          aria-label={accessibilityLabel ?? text ?? tooltip}
          ref={ref}
          disabled={disabled || loading}
          id={id}
          onClick={onPress}
          onMouseDown={onPressIn}
          type={type}
          style={{ width, color: textColor, alignContent, ...style }}
          data-testid={testID}
          // biome-ignore lint/a11y/noAutofocus: <explanation>
          autoFocus={autoFocus}
          {...webHighlightOnFocus}
        >
          {loading && (
            <Preloader
              absoluteFill
              transparent
              color={variantColor}
              size="small"
            />
          )}
          {icon && (
            <Icon name={icon} size={IconSize[iconSize]} color={iconColor} />
          )}
          {text}
        </button>
      </Tooltip>
    );
  },
);

export { KButton };

import { makeStyles } from "@mui/styles";
import { PropsWithChildren, useMemo } from "react";
import { Text, View } from "react-native";

import { morphClassNameAsStyle } from "../../../../utils/morphClassNameAsStyle";
import { AutoSizeWrapperProps } from "./autoSizeWrapper.types";

export const AutoSizeWrapper = ({
  children,
  value,
  inputStyle,
  containerStyle,
}: PropsWithChildren<AutoSizeWrapperProps>) => {
  const classes = useStyles();

  const containerStyles = useMemo(
    () => [containerStyle, morphClassNameAsStyle(classes.container)],
    [classes.container, containerStyle],
  );

  const sizerStyles = useMemo(
    () => [inputStyle, morphClassNameAsStyle(classes.sizer)],
    [classes.sizer, inputStyle],
  );

  return (
    <View style={containerStyles}>
      <Text style={sizerStyles}>{`${value} `}</Text>
      {children}
    </View>
  );
};

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    flexShrink: 1,
    display: "grid",
    overflowY: "auto",

    "& > input, & > textarea": {
      gridArea: "1 / 1 / 1 / 1",
    },

    "&:not(:has(textarea)) $sizer": {
      display: "none",
    },
  },

  sizer: {
    gridArea: "1 / 1 / 1 / 1",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    visibility: "hidden",
    pointerEvents: "none",
  },
});

import { Portal } from "./portal";

interface PortalConfig {
  portalHostname?: string;
}

export function withPortal<P extends PortalConfig>(
  Component: React.ComponentType<P>,
): (props: P) => JSX.Element;
export function withPortal<P extends PortalConfig>(
  Component: React.ComponentType<P>,
  portalConfig: PortalConfig,
): (props: P) => JSX.Element;
export function withPortal<P extends PortalConfig>(
  Component: React.ComponentType<P>,
  portalConfig?: PortalConfig,
): (props: P) => JSX.Element {
  return (props: P) => {
    const config = portalConfig ?? props;

    return (
      <Portal hostname={config.portalHostname}>
        <Component {...props} />
      </Portal>
    );
  };
}

import { useMemo, useRef } from "react";

import {
  StyledNestedTextMatch,
  StyledNestedTextMatcher,
} from "./styledNestedText.types";

let uniqueIndex = 0;
function getUniqueIndex() {
  return (uniqueIndex++).toString();
}

export function useExplodedTextFromMatchers(
  text: string,
  matchers: StyledNestedTextMatcher[] | undefined,
) {
  const matches = useRef<Record<string, StyledNestedTextMatch>>({});
  const uniqueIdentifier = useRef(getUniqueIndex()).current;

  const [generateToken, tokenRegExp] = useMemo(() => {
    return [
      (count: number) => `<_${uniqueIdentifier}-${count}_>`,
      new RegExp(`(<_${uniqueIdentifier}-\\d+_>)`, "g"),
    ];
  }, [uniqueIdentifier]);

  const tokenizedText = useMemo(() => {
    let _tokenizedText = text;
    let counter = 0;
    matches.current = {};

    if (matchers !== undefined) {
      for (const matcher of matchers) {
        _tokenizedText = _tokenizedText.replace(
          matcher.pattern,
          (...replacerArgs) => {
            counter += 1;
            const token = generateToken(counter);
            const matchedText = replacerArgs[0];
            const offset = replacerArgs[replacerArgs.length - 2];

            matches.current[token] = {
              matcher,
              matchedText,
              offset,
            };

            return token;
          },
        );
      }
    }

    return _tokenizedText;
  }, [generateToken, matchers, text]);

  return [tokenizedText, tokenRegExp, matches.current] as const;
}

import mappings from "../../assets/icons/mapping.json";
import { IconName } from "./icon.types";

export type FontIconName = Extract<
  IconName,
  `font-${any}`
> extends `font-${infer N}`
  ? N
  : never;

export function getFontIcon(name: FontIconName) {
  return String.fromCodePoint(mappings[`font-${name}`]);
}

import { SubMentionComponentProps } from "@draft-js-plugins/mention/lib/Mention";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

import { ColorStyle } from "@kraaft/ui";

interface EntryTextProps extends Omit<SubMentionComponentProps, "children"> {
  valid: boolean;
}

const EntryText = ({
  children,
  valid,
}: React.PropsWithChildren<EntryTextProps>) => {
  const classes = useEntryTextStyles();
  return (
    <span
      className={clsx(classes.suggestion, !valid && classes.suggestionError)}
    >
      {children}
    </span>
  );
};

export { EntryText };

export const useEntryTextStyles = makeStyles({
  suggestion: {
    fontWeight: "bold",
    color: ColorStyle.ACTION_CTA,
  },
  suggestionError: {
    color: ColorStyle.ACTION_DESCTRUCTIVE,
  },
});

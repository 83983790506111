import {
  forwardRef,
  PropsWithChildren,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Platform,
  StyleSheet,
  TextInput as RNTextInput,
  TextInputProps as RNTextInputProps,
} from "react-native";
import { noop } from "ts-essentials";

import { isNative } from "@kraaft/helper-functions";

import { ColorStyle } from "../../../constants/color";
import { FontSize } from "../../../constants/fontSize";
import { Spacing } from "../../../constants/spacing";
import { AutoSizeWrapper } from "./autoSizeWrapper";
import { ScrollContentWrapper } from "./scrollContentWrapper";
import {
  TextInputWithAutoSizeHandle,
  TextInputWithAutoSizeProps,
} from "./textInputWithAutoSize.types";

export const DISABLE_AUTOCOMPLETE_PROPS = {
  autoComplete: "off",
  dataSet: {
    lpignore: true,
    "form-type": "other",
  },
} as Partial<RNTextInputProps>;

export const TextInputWithAutoSize = forwardRef<
  TextInputWithAutoSizeHandle,
  PropsWithChildren<TextInputWithAutoSizeProps>
>(
  (
    {
      children,
      value,
      onChange,
      onChangeText,
      onFocus,
      onBlur,
      editable,
      accessibilityLabel,
      multiline,
      placeholder,
      autoFocus,
      keyboardType,
      returnKeyType,
      returnKeyLabel,
      nativeID,
      onTextInput,
      onSelectionChange,
      onKeyPress,
      autoComplete,
      inputStyle,
      containerStyle,
      disableAutocomplete,
      autoSizeWrapperProps,
      onLayout,
    },
    externalTextInputRef,
  ) => {
    const [selection, setSelection] = useState<
      { start: number; end: number } | undefined
    >(undefined);
    const internalTextInputRef = useRef<RNTextInput | null>(null);

    const handle = useMemo<TextInputWithAutoSizeHandle>(
      () => ({
        getWebElement: () => internalTextInputRef.current,
        focus: () => {
          internalTextInputRef.current?.focus();
        },
        blur: () => {
          internalTextInputRef.current?.blur();
        },
        isFocused: () => internalTextInputRef.current?.isFocused() ?? false,
        setSelection: Platform.select({
          web: (start: number, end: number) => setSelection({ start, end }),
          default: noop,
        }),
      }),
      [],
    );

    useImperativeHandle(externalTextInputRef, () => handle);

    const hasChildren = Boolean(children);
    const textInputStyle = useMemo(() => {
      return StyleSheet.flatten([
        styles.textInputBase,
        styles.textInputForPlatform,
        inputStyle,
      ]);
    }, [inputStyle]);

    return (
      <AutoSizeWrapper
        value={value}
        inputStyle={textInputStyle}
        containerStyle={[styles.textInputContainer, containerStyle]}
        nativeOnPress={autoSizeWrapperProps?.nativeOnPress}
        nativeShouldCatchPressEvents={
          autoSizeWrapperProps?.nativeShouldCatchPressEvents
        }
      >
        <RNTextInput
          ref={internalTextInputRef}
          value={value}
          onChange={onChange}
          onChangeText={onChangeText}
          onFocus={onFocus}
          onBlur={onBlur}
          style={[
            textInputStyle,
            hasChildren && styles.textInputWithChildrenForPlatform,
          ]}
          placeholderTextColor={ColorStyle.FONT_LOW_EMPHASIS}
          editable={editable}
          accessibilityLabel={accessibilityLabel}
          multiline={multiline}
          numberOfLines={1}
          placeholder={placeholder}
          autoFocus={autoFocus}
          keyboardType={keyboardType}
          returnKeyType={returnKeyType}
          returnKeyLabel={returnKeyLabel}
          nativeID={nativeID}
          onTextInput={onTextInput}
          onSelectionChange={onSelectionChange}
          onKeyPress={onKeyPress}
          autoComplete={autoComplete}
          selection={selection}
          onLayout={onLayout}
          {...(disableAutocomplete && DISABLE_AUTOCOMPLETE_PROPS)}
          children={isNative() ? children : undefined}
        />
        {!isNative() ? (
          <ScrollContentWrapper multiline={multiline} inputStyle={inputStyle}>
            {children}
          </ScrollContentWrapper>
        ) : null}
      </AutoSizeWrapper>
    );
  },
);

const styles = StyleSheet.create({
  textInputContainer: {
    ...Platform.select({
      native: {
        justifyContent: "center",
      },
    }),
  },
  textInputForPlatform: Platform.select({
    web: {
      outlineWidth: 0,
    },
    default: {
      padding: Spacing.NONE,
      paddingTop: Spacing.NONE,
    },
  }),
  textInputBase: {
    fontSize: FontSize.BODY,
    color: ColorStyle.FONT_HIGH_EMPHASIS,
  },
  textInputWithChildrenForPlatform: Platform.select({
    web: {
      color: "transparent",
      caretColor: ColorStyle.FONT_HIGH_EMPHASIS,
      overflow: "hidden",
    },
    default: {},
  }),
});

import attrAccept from "attr-accept";

interface FileError {
  message: string;
  code: string;
}

interface FileRejection {
  file: File;
  errors: FileError[];
}

export function checkInputFileSize({
  files,
  accept,
  maxSize,
}: {
  files: File[] | null;
  accept?: string | string[];
  maxSize?: number;
}) {
  const acceptedFiles: File[] = [];
  const fileRejections: FileRejection[] = [];

  if (files) {
    for (const file of files) {
      if (accept) {
        const shouldOverrideCheck =
          (Array.isArray(accept) && accept.includes("*")) || accept === "*";
        const isValidFileType = shouldOverrideCheck
          ? true
          : attrAccept(file, accept);

        if (!isValidFileType) {
          fileRejections.push({
            file: file,
            errors: [
              {
                message: `File type does not match ${accept}`,
                code: "file-invalid-type",
              },
            ],
          });
          return;
        }
      }
      if (maxSize) {
        if (file.size > maxSize) {
          fileRejections.push({
            file: file,
            errors: [
              {
                message: `File cannot be larger than ${maxSize}`,
                code: "file-is-too-big",
              },
            ],
          });
          return;
        }
      }
      acceptedFiles.push(file);
    }
  }

  return { status: fileRejections.length === 0, acceptedFiles, fileRejections };
}

import { StyleSheet, View } from "react-native";
import { MarkOptional } from "ts-essentials";

import { Spacing } from "../../constants/spacing";
import { Text } from "../../display/text";
import { BaseActionCard, type BaseActionCardProps } from "./baseActionCard";

interface SummarizedActionCardProps
  extends MarkOptional<BaseActionCardProps, "accessibilityLabel"> {
  label: string;
  summary?: string;
  numberOfLines?: number;
  selectable?: boolean;
}

export const SummarizedActionCard = ({
  label,
  summary,
  numberOfLines = 1,
  selectable = false,
  ...baseActionCardProps
}: SummarizedActionCardProps) => {
  return (
    <BaseActionCard
      accessibilityLabel={baseActionCardProps.accessibilityLabel ?? label}
      id={baseActionCardProps.id}
      onPress={baseActionCardProps.onPress}
      disabled={baseActionCardProps.disabled}
    >
      <View style={styles.content}>
        <Text
          size="BODY"
          color="FONT_HIGH_EMPHASIS"
          style={styles.text}
          numberOfLines={numberOfLines}
          selectable={selectable}
        >
          {label}
        </Text>
        {summary ? (
          <Text
            size="BODY"
            color="FONT_LOW_EMPHASIS"
            style={styles.summary}
            numberOfLines={numberOfLines}
            selectable={selectable}
          >
            {summary}
          </Text>
        ) : null}
      </View>
    </BaseActionCard>
  );
};

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S8,
  },
  text: {
    flexGrow: 1,
    flexShrink: 0,
  },
  summary: {
    flexGrow: 0,
    flexShrink: 1,
  },
});

import React, { useCallback } from "react";
import {
  Pressable,
  PressableStateCallbackType,
  StyleSheet,
  View,
} from "react-native";

import { highlightOnFocus } from "@kraaft/shared/core/utils/highlightOnFocus";

import { ColorStyle } from "../../constants/color";
import { PixelSize } from "../../constants/pixelSize";
import { Spacing } from "../../constants/spacing";
import { Icon } from "../../display/icon";
import { TrackableViewProps } from "../../types";
import { BaseActionCardHoverWrapper } from "./baseActionCardHoverWrapper";

export type BaseActionCardProps = TrackableViewProps & {
  id?: string;
  onPress?: () => void;
  disabled?: boolean;
  hideChevron?: boolean;
};

export const BaseActionCard = ({
  children,
  id,
  onPress,
  disabled,
  hideChevron = false,
  ...trackableViewProps
}: React.PropsWithChildren<BaseActionCardProps>) => {
  const getPressableStyle = useCallback(
    (state: PressableStateCallbackType) => [
      styles.container,
      state.pressed && styles.containerPressed,
    ],
    [],
  );

  return (
    <BaseActionCardHoverWrapper>
      <Pressable
        id={id}
        style={getPressableStyle}
        onPress={onPress}
        disabled={disabled}
        accessibilityLabel={trackableViewProps.accessibilityLabel}
        {...highlightOnFocus}
      >
        <View style={styles.contentContainer}>{children}</View>
        {!hideChevron && (
          <Icon name="chevron-right" color={ColorStyle.FONT_LOW_EMPHASIS} />
        )}
      </Pressable>
    </BaseActionCardHoverWrapper>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    gap: Spacing.S8,
    minHeight: PixelSize.S40,
    paddingVertical: Spacing.S8 + Spacing.S4,
  },
  containerPressed: {
    opacity: 0.75,
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
});

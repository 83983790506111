import React, { type CElement, useMemo } from "react";
import { StyleSheet, Text, type TextProps } from "react-native";

import { StyledNestedTextMatcher } from "./styledNestedText.types";
import { useExplodedTextFromMatchers } from "./useExplodedTextFromMatchers";

function sanitizeText(text: string, multiline: boolean | undefined) {
  if (multiline) {
    return text;
  }

  return text.replace(/\n/g, "");
}

interface StyledNestedTextProps extends TextProps {
  text: string;
  matchers: StyledNestedTextMatcher[] | undefined;
  multiline?: boolean;
  innerTextProps?: TextProps;
  suspendInterpolation?: boolean;
}

export const StyledNestedText = ({
  text,
  matchers,
  multiline,
  innerTextProps,
  suspendInterpolation,
  ...props
}: StyledNestedTextProps) => {
  const [tokenizedText, tokenRegExp, matches] = useExplodedTextFromMatchers(
    text,
    matchers,
  );

  const interpolatedText = useMemo(
    () =>
      React.createElement(
        Text,
        props,
        ...tokenizedText
          .split(tokenRegExp)
          .reduce<CElement<TextProps, Text>[]>((allNodes, part, index) => {
            if (!part) {
              return allNodes;
            }
            const match = matches[part];

            if (match !== undefined) {
              const { onPress, onLongPress, style, overrideTextProps } =
                match.matcher;
              allNodes.push(
                React.createElement(
                  Text,
                  {
                    onPress: () => onPress?.(match),
                    onLongPress: () => onLongPress?.(match),
                    ...innerTextProps,
                    ...overrideTextProps,
                    style: StyleSheet.flatten([
                      innerTextProps?.style,
                      overrideTextProps?.style,
                      style,
                    ]),
                    key: index,
                  },
                  sanitizeText(match.matchedText, multiline),
                ),
              );
            } else {
              allNodes.push(
                React.createElement(
                  Text,
                  { ...innerTextProps, key: index },
                  sanitizeText(part, multiline),
                ),
              );
            }

            return allNodes;
          }, []),
      ),
    [innerTextProps, matches, multiline, props, tokenRegExp, tokenizedText],
  );

  return useMemo(
    () =>
      suspendInterpolation
        ? React.createElement(Text, props, text)
        : interpolatedText,
    [suspendInterpolation, interpolatedText, props, text],
  );
};

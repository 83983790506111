import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { PropsWithChildren } from "react";

import { ORDERABLE_LIST_ANIMATION_DURATION_MS } from "./orderableList.constants";

interface HideProps {
  hidden: boolean;
}

export const Hide = ({ children, hidden }: PropsWithChildren<HideProps>) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, hidden && classes.containerHidden)}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "grid",
    transition: `grid-template-rows ${ORDERABLE_LIST_ANIMATION_DURATION_MS}ms`,
    gridTemplateRows: "1fr",
    flexGrow: 1,
  },
  containerHidden: {
    gridTemplateRows: "0fr",
  },
  content: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
});

import { KeyOfEnum } from "@kraaft/helper-types";

export const FontSize = {
  MINI: 11,
  SMALL: 12,
  MEDIUM: 14,

  BODY: 16,
  TITLE: 18,
  SUBTITLE: 16,
  H2: 20,
  H1: 22,
} as const;

export type FontSize = KeyOfEnum<typeof FontSize>;

import { EntryComponentProps } from "@draft-js-plugins/mention/lib/MentionSuggestions/Entry/Entry";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { MarkOptional } from "ts-essentials";

import { Color, ColorStyle, FontSize, PixelSize } from "@kraaft/ui";

import { Suggestion } from "./types/suggestions";

interface EntryProps extends EntryComponentProps {
  mention: MarkOptional<Suggestion, "value" | "label">;
}

const Entry = ({
  mention,
  isFocused,
  searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
  selectMention, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...parentProps
}: EntryProps) => {
  const classes = useStyle();

  return (
    <div {...parentProps} className={classes.withSeparatorContainer}>
      <span
        className={clsx(classes.itemBase, isFocused && classes.itemBaseFocused)}
      >
        <span className={classes.item}>
          {mention.name}
          <span className={classes.value}>&nbsp;{mention.placeholder}</span>
        </span>
      </span>
      <span className={clsx("itemSeparator", classes.itemSeparator)} />
    </div>
  );
};

export { Entry };

const useStyle = makeStyles({
  withSeparatorContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: Color.WHITE,
  },
  itemBase: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: Color.WHITE,
    height: PixelSize.S32,
  },
  itemBaseFocused: {
    backgroundColor: ColorStyle.BACKGROUND_STANDARD,
  },
  item: {
    fontSize: FontSize.MEDIUM,
    fontFamily: "Apercu",
    padding: "8px 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "block",
    textOverflow: "ellipsis",
    fontWeight: "bold",
  },
  itemSeparator: {
    backgroundColor: ColorStyle.SEPARATOR,
    height: 1,
    marginLeft: 12,
  },
  value: {
    fontWeight: "normal",
  },
});

import { StyleSheet, View } from "react-native";

import { ColorValue, Spacing } from "../../constants";
import { Icon, IconName } from "../icon";
import { Text } from "../text";

export type LabelWithIconProps = {
  iconName: IconName;
  label: string;
  color: ColorValue;
  numberOfLines?: number;
  selectable?: boolean;
};

export const LabelWithIcon = ({
  iconName,
  label,
  color,
  numberOfLines,
  selectable,
}: LabelWithIconProps) => {
  return (
    <View style={styles.content}>
      <Icon name={iconName} color={color} />
      <Text
        size="BODY"
        color={color}
        style={styles.text}
        numberOfLines={numberOfLines}
        selectable={selectable}
      >
        {label}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.S4,
  },
  text: {
    flexGrow: 1,
    flexBasis: 0,
  },
});
